.app_container {
    /* background-color: red; */
    /* display: flex; */
    margin: auto;
    padding: 15px 0px;
    /* border: 1px solid red; */
    width: 100%;
   background-color: #FFF2E6;
  
  }
  

  .app_container .form_container {
    display: flex;
    flex-direction: column;
    width: calc(100% - 275px);
    padding: 20px 70px;
    box-sizing: border-box;
  }
  
  .app_container .progress_container .bloc_tabs {
    width: 80%;
    margin: auto;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  
  .tabs {
    display: flex;
    align-items: center;
    font-size: 24px;
    padding: 20px 0;
    cursor: pointer;
    position: relative;
    background: transparent;
    gap: 10px;
    transition: color 0.3s ease-in-out;
  }
  
  .tabs::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    bottom: -30px;
    width: 2px;
    height: calc(100% + 30px);
    background-color: #323232;
    transform: translateX(-50%);
    transition: background-color 0.3s;
  }
  
  .active_tabs {
    color: #4160fb;
  }
  
  .active_tabs::before {
    background-color: #000;
  }
  
  .stepInfo {
    display: flex;
    flex-direction: column;
  }
  
  .stepInfo span {
    font-weight: bold;
  }
  
  .stepInfo p {
    margin: 0;
    font-size: 14px;
    color: #666;
  }
  
  .last_tab {
    margin-bottom: 0;
  }
  
  
  .app_container .progress_container {
    /* background-image: linear-gradient(180deg, rgba(255, 124, 1, 0.00) 0.99%, #FF7C01 100%);
    background: url("/bgx.png") no-repeat; */
    width: 25rem;
    background-size: cover;
    border-radius: 1rem;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    background-repeat: round;
    padding: 0% 15px;
    position: relative;
  }
  
  .app_container .form_content {
    width: 100%;
    position: relative;
  }
  
  .app_container .form_container {
    display: flex;
    flex-direction: column;
    width: calc(900px - 275px);
    padding: 20px 70px;
  }
  
  /* .app_container .progress_container .bloc_tabs {
    width: 80%;
    margin: auto;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  } */
  
  /* .tabs {
    display: flex;
    font-size: 24px;
    padding: 3% 0;
    cursor: pointer;
    box-sizing: content-box;
    position: relative;
    outline: none;
    background: transparent;
    gap: 10px;
  
  } */
  
  /* .tabs::before {
    content: '';
    position: absolute;
    left: 12px;
    border-radius: 20px;
    top: 50%;
    bottom: -30px;
    width: 2px;
    background-color: #323232;
    transform: translateX(-50%);
    transition: background-color 0.3s;
  } */
  
  /* .active_tabs {
    color: #0026e1;
  }
  
  .active_tabs::before {
    background-color: #000;
  }
   */
  .form_content .navigation_buttons {
    font-size: 13px;
    position: absolute;
    bottom: 0;
    left: 50%;
    display: flex;
    transform: translateX(-50%);
    justify-content: space-between;
    border-radius: 20px;
    gap: 50px;
  }
  
  .stepperProgress {
    list-style-type: none;
    padding: 0;
  }
  
  .navigation_buttons .next_btn {
    width: 100px;
    height: 35px;
    flex-shrink: 0;
    border: none;
    color: #fff;
    border-radius: 5px;
    background: #FD7401;
    cursor: pointer;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
  
  .navigation_buttons .prev_btn {
    width: 100px;
    height: 35px;
    flex-shrink: 0;
    border: none;
    color: #fff;
    border-radius: 5px;
    background: #FD7401;
    cursor: pointer;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }
  
  .prev_btn:disabled,
  .next_btn:disabled {
    /* display: none; */
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .page_pg {
    font-size: 13px;
    color: #5a5a5a;
    line-height: 1;
    word-wrap: break-word;
  }
  
  .stepNum {
    font-size: 12px;
    color: #bfbfbf;
  }
  
  .last_tab::before {
    display: none;
  }
  
  .active_content {
    display: block;
  }
  
  .stepInfo span {
    color: #000;
    font-size: 16px;
  }
  
  .stepInfo p {
    color: #000;
    font-size: 13px;
    font-weight: 300;
  }
  
  .content_tabs {
    flex-grow: 1;
    /* background-color: red; */
  
  }
  
  .contentShow {
    width: 100%;
    height: 100%;
    /* display: none; */
  }
  
  .inputContainers{
    padding: 15px 20px;
    margin-bottom: 20px;
    /* background-color: pink; */
    border: 1px solid lightgray;
    border-radius: 10px;
    /* display: flex; */
    background-color: white;
  }

  .inputContainers label{
    font-size: 14px;
    font-weight: 600;
    color: black;
  }

  .inputContainers input,.inputContainers select{
    border: 1px solid black;
    outline: none;
    background-color: #F7FAFF
    ;
  }

  /* .inputContainers input[type=file]{
    padding: 10px !important;
  } */

  
  /* / Media query for responsiveness / */
  @media (min-width: 1024px) {
    .tabs {
        width: 100%;
        flex-direction: column;
        align-items: center;
        gap: 0;
    }

    .app_container .form_content {
        width: 100%;
        position: relative;
    }

    .app_container {
        flex-direction: column;
    }

    .page_pg {
        line-height: 1;
    }

    .app_container .progress_container {
        width: 100%;
        height: 100%;
    }

    .bloc_tabs {
        display: flex !important;
        flex-direction: row !important;
    }

    .stepInfo p {
        display: none;
    }

    .tabs::before {
        left: 100%;
        top: 2%;
        height: 85%;
        transform: rotate(90deg);
    }

    .app_container .progress_container {
        background: none;
        margin-bottom: -30px;
    }
}

  
  @media (max-width: 1023px) {
    .tabs {
      width: 100%;
      flex-direction: column;
      align-items: center;
      gap: 0;
  
    }
  
    .app_container .form_content {
      width: 100%;
      position: relative;
    }
  
    .app_container {
      flex-direction: column;
    }
  
    .page_pg {
      line-height: 1;
    }
  
    .app_container .progress_container {
      width: 100%;
      height: 100%;
    }
  
    .bloc_tabs {
      display: flex !important;
      flex-direction: row !important;
    }
  
    .stepInfo p {
      display: none;
    }
  
    .stepInfo span {
      font-size: 10px;
    }
  
    .tabs::before {
      left: 100%;
      top: 2%;
      height: 85%;
      transform: rotate(90deg);
    }
  
    .app_container .progress_container {
      background: none;
      margin-bottom: -30px;
    }
  }
  
  @media (max-width: 767px) {
  
    .tabs {
      width: 100%;
    }
  
    .app_container {
      flex-direction: column !important;
    }
  
    .app_container .form_content {
      width: 100%;
      position: relative;
    }
  
    .app_container .progress_container {
      background-image: none;
      background: none;
      width: 100%;
    }
  
    .content_tabs, .contentShow {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  
    .tabs::before {
      left: 100%;
      top: 12%;
      height: 55%;
      transform: rotate(90deg);
    }
  }
  
  @media (max-width: 374px) {
    .tabs::before {
      left: 100%;
      top: 14%;
      height: 50%;
    }
  }
  
  